import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

//Style
import "./NavbarMobileBottom.css";

//Context
import { AuthContext } from "../../context/AuthContext";
import { authTypes } from "../../types/authTypes";

const NavbarMobileBottom = () => {
  const { dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch({ type: authTypes.logout });
    localStorage.removeItem("token");
  };

  return (
    <>
      <nav className="navbar-mobile-bottom-container">
        <div className="nb-menus-container">
          <div
            className="menu-container"
            onClick={() => navigate("/admin/metodos-de-pago")}
          >
            <img
              src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/payment-methods-icon.webp"
              alt=""
              className="menu-icon"
            />
            <h1>Metodos de Pago</h1>
          </div>
          <div
            className="menu-container line"
            onClick={() => navigate("/admin/perfil")}
          >
            <img
              src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/profile-icon.webp"
              alt=""
              className="menu-icon"
            />
            <h1>Perfil</h1>
          </div>
          <div
            title="Cerrar sesión"
            className="menu-container line"
            onClick={handleLogout}
          >
            <h1>Cerrar Sesión</h1>
            <img
              src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/logout-icon.webp"
              alt=""
              className="menu-icon"
            />
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarMobileBottom;
